import React, { useState } from 'react'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Markdown from 'react-markdown'
import { KnowledgeArticle } from '../services'
import remarkGfm from 'remark-gfm'
import { TableOfContents } from './TableOfContents'
import LinkIcon from '@mui/icons-material/Launch'
import { SnomedConcept } from '../interfaces/Concept.interface'

interface CardProps {
  header: React.ReactNode
  content: React.ReactNode
  onClick: () => void
  extraCssClasses?: string
  icon?: string
}

const Card: React.FC<CardProps> = ({
  header,
  content,
  onClick,
  extraCssClasses,
  icon,
}) => {
  return (
    <div
      className={
        'card clickable' + (extraCssClasses ? ' ' + extraCssClasses : '')
      }
      onClick={() => onClick()}
    >
      <div className="row small-gap">
        {icon && (
          <div>
            <img className={'source-icon'} src={icon} width={32} height={32} />
          </div>
        )}
        <div className="column flex1">
          <div>{header}</div>
          <div>{content}</div>
        </div>
      </div>
    </div>
  )
}

interface SearchResultsProps {
  articles: KnowledgeArticle[]
  selectedOwners?: String[]
  isLoading: boolean
  term: string
  snomedConcept?: SnomedConcept
  useIcons?: boolean
  onArticleChange: (text: KnowledgeArticle | null) => void
}

export const SearchResults: React.FC<SearchResultsProps> = (props) => {
  const [selectedArticleId, setSelectedArticleId] = useState(
    null as null | string,
  )
  const selectedArticle = props.articles.find(
    (source) => source.id === selectedArticleId,
  )

  const unselectArticle = () => {
    setSelectedArticleId(null)
    props.onArticleChange(null)
  }
  const selectArticle = (id: string) => {
    setSelectedArticleId(id)
    // addArticleToLocalStorageCache(id)
    // Scroll to the top:
    const article: KnowledgeArticle | undefined = props.articles.find(
      (source) => source.id === id,
    )
    if (article) {
      props.onArticleChange(article)
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <>
      {selectedArticle && (
        <>
          <button
            onClick={unselectArticle}
            className={'flat padded row small-gap no-margin-bottom'}
          >
            <ArrowBackIcon />
            <span>Tilbake</span>
          </button>
          <Article article={selectedArticle} />
        </>
      )}

      <div className={'break-word column gap padded'}>
        {selectedArticle ? (
          props.articles.length >= 2 ? (
            <h2>Øvrige treff:</h2>
          ) : (
            <></>
          )
        ) : (
          <div>
            Søketreff <strong>{props.term}</strong>{' '}
            <small className={'muted-text'}>({props.snomedConcept?.id})</small>
          </div>
        )}
        {props.articles
          .filter((x) => x.id !== selectedArticleId)
          .map((sourceResponse) => {
            const isArticleLinkOnly =
              sourceResponse.link !== '' &&
              sourceResponse.content.text === '' &&
              sourceResponse.content.intro === '' &&
              sourceResponse.content.practical === '' &&
              sourceResponse.content.rationale === ''
            return (
              <Card
                key={sourceResponse.id}
                onClick={() =>
                  isArticleLinkOnly
                    ? window.open(sourceResponse.link, '_blank')
                    : selectArticle(sourceResponse.id)
                }
                header={
                  <div className={'row space-between owner-text'}>
                    <span>{sourceResponse.owner}</span>
                    {isArticleLinkOnly ? <LinkIcon /> : <ReadMoreIcon />}
                  </div>
                }
                content={sourceResponse.title}
                icon={
                  props.useIcons
                    ? iconFromOwner(sourceResponse.owner[0] || '')
                    : undefined
                }
              />
            )
          })}
        {props.isLoading && <PlaceholderLoaderArticles />}
      </div>
    </>
  )
}

const iconFromOwner = (owner: string): string => {
  switch (owner) {
    case 'Vestre Viken':
    case 'VestreViken':
      return 'assets/source-icons/vestreviken.ico'
    case 'Helsedirektoratet':
      return 'assets/source-icons/hdir.png'
    case 'Felleskatalogen':
      return 'assets/source-icons/fk.png'
    case 'BMJ':
    case 'Bmj':
    case 'bmj':
      return 'assets/source-icons/bmj.png'
    case 'VAR':
    case 'Var':
    case 'Varnett':
      return 'assets/source-icons/varnett.png'
    case 'NEL':
    case 'nel':
      return 'assets/source-icons/nel.png'
    default:
      return ''
  }
}

const Article: React.FC<{ article: KnowledgeArticle }> = ({ article }) => {
  const textToId = (str: string) => {
    return str
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/æ/g, 'ae')
      .replace(/ø/g, 'oe')
      .replace(/å/g, 'aa')
      .replace(/[^a-z0-9-]/g, '')
  }

  const getTextFromChildren = (children: any): string => {
    if (typeof children === 'string') {
      return children
    }

    if (Array.isArray(children)) {
      for (let i = 0; i < children.length; i++) {
        const text = getTextFromChildren(children[i])
        if (text !== '') {
          return text
        }
      }
      return ''
    }

    return children && children.props
      ? getTextFromChildren(children.props.children)
      : ''
  }

  return (
    <article>
      <h1>{article.shortTitle || article.title}</h1>
      {article.professionalUpdate && (
        <div className="align-left">
          Siste faglig oppdatert: {article.professionalUpdate}
        </div>
      )}
      {article.productTitle !== undefined ||
      article.productUrl !== undefined ? (
        <div className="align-left">
          Hentet fra:{' '}
          <a href={article.productUrl} target="_blank" rel="noreferrer">
            {article.productTitle}
          </a>
        </div>
      ) : (
        ''
      )}
      {article.content && (
        <>
          <TableOfContents
            markdown={article.content.text || ''}
            textToId={textToId}
            extraChapters={[
              ...(article.content.practical !== ''
                ? [
                    {
                      title: 'Praktisk - slik kan anbefalingen følges',
                      id: 'article-h2-praktisk',
                    },
                  ]
                : []),
              ...(article.content.rationale !== ''
                ? [
                    {
                      title: 'Begrunnelse - dette er anbefalingen basert på',
                      id: 'article-h2-rationale',
                    },
                  ]
                : []),
            ]}
          />
          <Markdown>{article.content.intro}</Markdown>
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              h2(props: any) {
                return (
                  <h2
                    id={
                      props.children
                        ? textToId(getTextFromChildren(props.children))
                        : ''
                    }
                  >
                    {props.children}
                  </h2>
                )
              },
              // We want to wrap all tables in .table-container to make them scrollable on small screens
              table(props: any) {
                return (
                  <div className="table-container">
                    <table>{props.children}</table>
                  </div>
                )
              },
            }}
          >
            {article.content.text}
          </Markdown>

          {article.content.practical !== '' && (
            <>
              <h2 id={'article-h2-praktisk'}>
                Praktisk - slik kan anbefalingen følges
              </h2>
              <Markdown>{article.content.practical}</Markdown>
            </>
          )}
          {article.content.rationale !== '' && (
            <>
              <h2 id={'article-h2-rationale'}>
                Begrunnelse - dette er anbefalingen basert på
              </h2>
              <Markdown>{article.content.rationale}</Markdown>
            </>
          )}
        </>
      )}
      <br />
      {article.link && (
        <a
          className="align-left"
          href={article.link}
          target="_blank"
          rel="noreferrer"
        >
          {article.link}
        </a>
      )}
    </article>
  )
}

const PlaceholderLoaderArticles: React.FC = () => (
  <>
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}>&nbsp;</span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background'}
    />
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}>&nbsp;</span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background opacity-05'}
    />
    <Card
      onClick={() => {}}
      header={<span className={'owner-text'}>&nbsp;</span>}
      content={
        <p>
          <br />
        </p>
      }
      extraCssClasses={'animated-background opacity-02'}
    />
  </>
)
