import { CustomerConfig } from './types'

const config: CustomerConfig = {
  entraClientId: '1349667d-9ca0-45d9-a38c-b2a088c39fc8',
  meta: {
    title: 'Infobutton app',
    description: 'Infobutton demo app.',
  },
  aboutText: 'Denne appen demonstrerer hvordan HL7 Infobutton kan brukes til å hente informasjon fra anerkjente kilder på en standardisert måte via vårt API. Fordelene med HL7 Infobutton-standarden inkluderer enkel integrasjon med elektroniske pasientjournaler og andre helseinformasjonssystemer som støtter denne internasjonalt anerkjente løsningen. Dette sikrer rask tilgang til relevant og pålitelig helseinformasjon.',
  aboutLogos: [],
  theme: {
    background: '#44ff4e',
  },
  searchRefsets: [{ id: '', label: 'Indikasjon' }],
  contentOwners: [
    'BMJ',
    'Helsebiblioteket',
    'Felleskatalogen',
    'Helsedirektoratet',
    'Helsenorge',
    'NEL',
    'Varnett',
  ],
  mainBranch: 'MAIN/SNOMEDCT-NO/CONTEIR/REFSETS',
  useChat: false,
  useIcons: true,
  showFilterOptions: true,
  infobuttonMode: true,
}
export default config
